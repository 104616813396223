<template>
  <nav class="navbar navbar-expand-lg bg-light shadow-sm">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" alt="logo" class="img-logo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-3">
            <a class="nav-link fs-5" href="https://medicalprosoftware.com/" target="_blank">
              Main Site
            </a>
          </li>
          <li class="nav-item px-3">
            <router-link class="nav-link fs-5" to="/">Directories</router-link>
          </li>
          <!-- <li class="nav-item px-3">
            <router-link class="nav-link fs-5" to="/find-a-doctor">
              Find a Doctor
            </router-link>
          </li> -->
          <li class="nav-item px-3">
            <div class="btn-group">
              <a class="nav-link fs-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Login
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="https://app.medicalprosoftware.com/login.php" target="_blank">
                    Doctor
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://app.medicalprosoftware.com/login.php?type=Patient"
                    target="_blank">
                    Patient
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://vendor.medicalprosoftware.com/login.php" target="_blank">
                    Vendor
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item px-3">
            <div class="btn-group">
              <a class="nav-link fs-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Register
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="https://app.medicalprosoftware.com/register-doctor.php"
                    target="_blank">
                    Doctor
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://app.medicalprosoftware.com/register-patient.php"
                    target="_blank">
                    Patient
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://vendor.medicalprosoftware.com/register-vendor.php"
                    target="_blank">
                    Vendor
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item px-3">
            <div class="btn-group">
              <a class="nav-link fs-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Subscription
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="https://medicalproasia.com/collections/subscriptions" target="_blank">
                    View All
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://medicalproasia.com/products/medicalpro-elite-plan"
                    target="_blank">
                    Elite Plan
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://medicalproasia.com/products/medicalpro-premium-plan"
                    target="_blank">
                    Premium Plan
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="https://medicalproasia.com/products/standard" target="_blank">
                    Standard Plan
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item px-3">
            <div class="btn-group">
              <a class="nav-link fs-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Articles
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <router-link class="dropdown-item" to="/updates">
                    Updates
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/articles">
                    Articles
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/sponsored-articles">
                    Sponsored Articles
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style scoped>
.img-logo {
  width: 100px;
}

.router-link-active {
  font-weight: bold;
}

.dropdown-item {
  font-weight: normal;
  font-size: large;
  color: #70708f;
}

.dropdown-menu {
  border-radius: 0px;
}

.nav-link {
  color: rgb(30 41 59);
}

.router-link-active {
  color: rgb(75 93 214);
}

.nav-link:hover {
  color: rgb(75 93 214);
}
</style>
