import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/find-a-doctor",
    name: "Find a Doctor",
    component: () => import("../views/FindDoctor.vue"),
  },
  {
    path: "/doctor-information",
    name: "Doctor Information",
    component: () => import("../views/DoctorInformation.vue"),
  },
  {
    path: "/vendor-information",
    name: "Vendor Information",
    component: () => import("../views/VendorInformation.vue"),
  },
  {
    path: "/updates",
    name: "Updates",
    component: () => import("../views/BlogUpdates.vue"),
  },
  {
    path: "/articles",
    name: "Articles",
    component: () => import("../views/NormalArticles.vue"),
  },
  {
    path: "/sponsored-articles",
    name: "Sponsored Articles",
    component: () => import("../views/SponsoredArticles.vue"),
  },
  {
    path: "/view-updates",
    name: "View Updates",
    component: () => import("../views/ViewUpdates.vue"),
    meta: {
      noindex: true,
      nofollow: true,
    },
  },
  {
    path: "/view-article",
    name: "View Article",
    component: () => import("../views/ViewArticle.vue"),
    meta: {
      noindex: true,
      nofollow: true,
    },
  },
  {
    path: "/view-sponser-article",
    name: "View Sponser Article",
    component: () => import("../views/ViewSponserArticle.vue"),
    meta: {
      noindex: true,
      nofollow: true,
    },
  },

  {
    path: "/find-a-vendor",
    name: "Find a Vendor",
    component: () => import("../views/FindVendor.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | MedicalPro Directories";
  NProgress.configure({ showSpinner: false });
  NProgress.start();

  if (window.gtag) {
    window.gtag("config", "G-0BVXXBD3GN", {
      page_path: to.path,
    });
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
