import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import $ from "jquery";
import Multiselect from "@vueform/multiselect";
import { ComboBoxComponent } from "@syncfusion/ej2-vue-dropdowns";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "nprogress/nprogress.css";

const eventBus = createApp({});
eventBus.provide("eventBus", eventBus);

createApp(App)
  .use(store)
  .use(LoadingPlugin, { loader: "dots", color: "#355b7c" })
  .use(VueAxios, axios)
  .use($)
  .component("@vueform/multiselect", Multiselect)
  .use(router)
  .component("ej-combobox", ComboBoxComponent)
  .mount("#app");
